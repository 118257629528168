<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('branch.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('branch.pageTitle'),
                        href: '/admin/branches'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <Profile
                    v-model="model"
                    :validate="errors"
                    class="tw-h-full"
                    ref="profile"
                />
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'branch' })"
                class="btn-gray"
            >
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t('update') }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import Profile from './components/profile.vue'
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'branchEdit',
    components: {
        Profile
    },
    data () {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                branch_name_kh: null,
                branch_name_en: null,
                branch_address: null,
                branch_phone: null,
                branch_email: null,
                branch_website: null,
                image_logo: null,
                branch_code: null,
                office_rentals: [
                    {
                        contract_id: 0,
                        contract_period: null,
                        monthly_fee: null,
                        deposit_amount: null,
                        currency_id: 1,
                        owner_phone_number: null,
                        remarks: null,
                        contract_start_date: null,
                        contract_expiry_date: null,
                        // attached_file: null,
                        village_code: null,
                        commune_code: null,
                        district_code: null,
                        province_code: null,
                        // mime_type: null,
                        // attached_file_name: null,
                        notification_receiver: [],
                        attached_file: []
                    }
                ]
            },
            currencies: []
        }
    },
    created () {
        AppOptions.appContentFullHeight = true
        AppOptions.appContentClass = 'p-0'
    },
    computed: {
        ...mapState('corporate/branch', ['edit_data'])
    },
    methods: {
        ...mapActions('corporate/branch', [
            'find',
            'getCompany',
            'getCurrency',
            'getEmployees',
            'getProvince'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getCompany()
            await this.getCurrency()
            await this.getProvince()
            await this.getEmployees()
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: 'error', text: error.message })
            })
            this.setEditData()
            this.loading = false
        },
        async onUpdate () {
            this.errors = new Errors()
            this.waiting = true
            let file_upload_path = await this.$refs.profile.uploadLogo()
            this.$store
                .dispatch('corporate/branch/update', {
                    id: this.model.branch_id,
                    data: Object.assign({}, this.model, {
                        image_logo: file_upload_path
                    })
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$router.push({ name: 'branch' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id
                this.model.branch_id = this.edit_data.branch_id
                this.model.branch_name_kh = this.edit_data.branch_name_kh
                this.model.branch_name_en = this.edit_data.branch_name_en
                this.model.branch_address = this.edit_data.branch_address
                this.model.branch_phone = this.edit_data.branch_phone
                this.model.branch_email = this.edit_data.branch_email
                this.model.branch_website = this.edit_data.branch_website
                this.model.image_logo = this.edit_data.image_logo
                this.model.branch_code = this.edit_data.branch_code
                this.$refs.profile.loadLogoFileSource()
                if (this.edit_data.rental_contract.length > 0) {
                    this.model.office_rentals = []
                    this.edit_data.rental_contract.map(el => {
                        this.$refs.profile.provinceChange(el.province_code)
                        this.$refs.profile.districtChange(el.district_code)
                        this.$refs.profile.communeChange(el.commune_code)

                        let details = {
                            contract_id: el.contract_id,
                            branch_id: el.branch_id,
                            contract_start_date: el.contract_start_date,
                            contract_expiry_date: el.contract_expiry_date,
                            currency_id: el.currency_id,
                            contract_period: el.contract_period,
                            monthly_fee: el.monthly_fee,
                            deposit_amount: el.deposit_amount,
                            owner_phone_number: el.owner_phone_number,
                            province_code: el.province_code,
                            district_code: el.district_code,
                            commune_code: el.commune_code,
                            village_code: el.village_code,
                            remarks: el.remarks,
                            notification_receiver: el.notification_receiver
                                ? el.notification_receiver
                                      .split(',')
                                      .map(function (t) {
                                          return parseInt(t)
                                      })
                                : [],
                            attached_file: el.rental_contract_attachment
                        }
                        this.model.office_rentals.push(details)
                        el.rental_contract_attachment.map(element => {
                            this.$refs.profile.fileRecords.push({
                                name: element.attached_file_name,
                                type: element.mime_type,
                                size: 209129,
                                text: element.attached_file.split('.').pop(),
                                url: element.attached_file
                            })
                        })
                    })
                }
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BRANCH',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    },
    beforeRouteLeave (to, from, next) {
        AppOptions.appContentFullHeight = false
        AppOptions.appContentClass = ''
        next()
    }
}
</script>
